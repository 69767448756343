<template>
  <v-card>
    <v-card-title class="m-3">
      Bem-vindo ao Teros, nossa plataforma de gestão de incentivos fiscais.
      <template v-if="items.length">
        <br />Escolha o módulo que deseja acessar:
      </template>
    </v-card-title>
    <v-row no-gutters class="ml-4 mr-6 mb-5">
      <v-col v-if="shouldSelectClient" class="ml-4">
        Você precisa selecionar uma empresa no cabeçalho da tela para continuar.
      </v-col>
      <v-col v-else-if="!items.length" class="ml-4">
        Você não possui permissão para nenhum módulo do sistema. Contate seu consultor para mudar isso.
      </v-col>
      <template v-else>
        <v-col v-for="(item, key) in items" cols="6" class="mt-4" :key="key">
          <ShortcutIcon :title="item.name" :icon="item.icon" :to="item.to">
            {{ item.descricao }}
          </ShortcutIcon>
        </v-col>
      </template>
    </v-row>
    <v-icon></v-icon>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ShortcutIcon from "@/templates/home/components/shortcut-icon.vue";
import { AnyClient, UserTypeEnum } from "@/core/enums/user-types";
import getRecursiveProperty from "@/helpers/getRecursiveProperty";

export default {
  components: {
    ShortcutIcon,
  },
  computed: {
    ...mapGetters(["clientId", "clients", "jwtData", "user"]),
    isClient: function () {
      return AnyClient.includes(this.user.tipo_usuario);
    },
    items: function () {
      const permissoes = this.user?.empresa?.permissoes || {};
      const modulos = [
        {
          icon: "mdi-square-edit-outline",
          name: "Cadastro",
          descricao: "Módulo para cadastramento de empresas e consultores.",
          module: "Cadastro",
        },
        {
          icon: "mdi-clipboard-text-outline",
          name: "Dashboard e Relatórios",
          descricao:
            "Módulo para análise de relatórios e dashboards para gerenciamento dos incentivos, com possibilidade de exportar relatórios.",
          module: "Dashboard",
        },
        {
          icon: "mdi-account-box-multiple-outline",
          name: "Colaboradores",
          descricao:
            "Módulo com todos os colaboradores informados na Fopag, timesheet, ponto e provisões, sendo possível cadastrar colaboradores do cliente e realizar DE>Para entre colaboradores.",
          module: "Colaboradores",
        },
        {
          name: "Projetos",
          icon: "mdi-file-document-outline",
          descricao:
            "Módulo para inclusão de projetos pelo cliente para análise de enquadramento, preenchimento da ficha de projetos e acompanhamento da evolução do preenchimento e da revisão.",
          module: "Projetos",
        },
        {
          name: "Dispêndios",
          icon: "mdi-percent-outline",
          descricao:
            "Módulo para cadastramento de encargos previdenciários, padronização e De>Para de rubricas e resumo do cálculo de dispêndios por colaborador.",
          module: "Dispendios",
        },
        {
          name: "Captação",
          icon: "mdi-cash-register",
          descricao:
            "Módulo voltado para cadastro de rubricas do cálculo de captação de recursos, configuração de orçamentos por projetos e comprovação financeira de recursos.",
          module: "Captacao",
        },
        {
          name: "Timesheets",
          icon: "mdi-clock-outline",
          descricao:
            "Módulo para criação de atividades e etapas de projetos para nossos clientes alocarem ou importar timesheets.",
          module: "Timesheet",
        },
        {
          name: "Faturamento",
          icon: "mdi-currency-usd",
          descricao:
            "Módulo para importação de NF’s de faturamento, conciliação de devoluções e cadastramento de produtos e modelos de LI.",
          module: "Faturamento",
        },
        {
          name: "Importações",
          icon: "mdi-tray-arrow-down",
          descricao:
            "Módulo para centralização das importações em geral (fopags, provisões, controle de ponto, timesheets e NFs em geral), sendo possível também importar arquivos em cada módulo relacionado.",
          module: "Importacoes",
        },
      ]

      return (UserTypeEnum.ADMINISTRADOR === this.user?.tipo_usuario || permissoes.total) ? modulos : modulos.filter(({ module }) => !!getRecursiveProperty(module, permissoes));
    },
    shouldSelectClient: function () {
      return ![UserTypeEnum.ADMINISTRADOR, UserTypeEnum.CLIENTE_ADMINISTRADOR].includes(this.user?.tipo_usuario) && !this.clientId && this.clients.length > 1;
    },
  },
  created: function () {
    if (this.$store.getters.cannotAccessMessage) {
      this.$notify({
        group: "geral",
        duration: 5000,
        type: "error",
        title: "Acesso negado.",
        text: "Você não tem permissão para acessar essa tela.",
      });
      this.$store.commit("cannotAccessMessage", false);
    }
  },
};
</script>
